/* ======= BOOTSTRAP VARIABLES, IMPORT & FONTS ======= */

$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Raleway', sans-serif;
$headings-font-weight: 700;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 2.4;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.55;
$h4-font-size: $font-size-base * 1.3;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;
$display4-size: 2.2rem !default;
$primary: #1d4583;
$black: #000000;
$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "black": #000000
  ),
  $theme-colors
);
$btn-font-weight: 600;
$btn-border-width: 2px;
$btn-border-radius: 0;
$btn-border-radius-lg: 0;
$btn-border-radius-sm: 0;
$card-border-radius: 0;
$box-shadow: 0 .5rem 1rem rgba($black, .15);


@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,700,700i,900,900i');

@import '../../../node_modules/bootstrap/scss/functions';
@import 'bootstrap-variables';
@import 'font-awesome-variables';

@import '../../../node_modules/bootstrap/scss/bootstrap';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../../node_modules/@fortawesome/fontawesome-free/scss/brands';


/* ======= LAYOUT ======= */

.container-fluid {
	&.mw {
		max-width: 85%;
	}
}

#intro {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-home.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}

#intro-industriali {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-industriali.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}


#intro-resina {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-resina.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}

#intro-capannoni {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-capannoni.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}

#intro-sanitaria {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-sanitaria.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}

#intro-alimentare {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-intro-alimentare.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}

#come {
	> .d-lg-flex {
		.flex-item {
			width: 100%;
			background: 0 none;
		}
		.flex-img {
			width: 100%;
			background: url(img/img-come.jpg) no-repeat 50% 50%;
			min-height: 30em;
			background-size: cover;
			@include media-breakpoint-up(lg) {
				min-height: 40em;
			}
		}
	}
}

/* ======= ELEMENTS ======= */

.jumbotron {
	@include media-breakpoint-up(sm) {
		padding: ($jumbotron-padding * 1.25) $jumbotron-padding;
	}
}

h4 {color: #4671b5 !important;}

.card {
	i.fas,
	i.fab,
	i.fa {
		color: #4671b5;
	}
	#products & {
		@include media-breakpoint-down(md) {
			margin-bottom: 3rem;
		}
		@include media-breakpoint-up(md) {
			height: 100%;
		}
		@include media-breakpoint-up(xl) {
			width: 75%;
		}
		.card-title {
			font-weight: 900;
			text-transform: uppercase;
			color: #4671b5;
		}
	}
}


/* ======= UTILS ======= */
.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa !important;
}

.navbar-light .navbar-nav .nav-link.active {color: $primary;}
.navbar-nav {
	text-transform: uppercase;
	font-weight: 600;
}


/* ======= TYPO ======= */

@include media-breakpoint-down(md) {
	html {
		font-size: .875rem;
	}
}

h2,
.h2 {
	font-weight: 300;
}


/* ======= FOOTER ======= */


/* ======= FORM ======= */

$form_bg: transparent;

$form_div_width: 100%;

$form_label_width: 35%;
$form_label_color: $body-color;
$form_label_fontsize: 120%;

$form_input_color: $body-color;
$form_input_bg: #fff;
$form_input_border: 1px solid #ccc;
$form_input_width: 60%;
$form_input_padding: 1% 2%;
$form_input_fontsize: 110%;

$form_input_color_focus: #333;
$form_input_border_focus: 1px solid #ccc;
$form_input_bg_focus: transparent;

$form_privacy_fontsize: 80%; 

$form_invia_bg: $primary;
$form_invia_bg_hover: #4671b5;
$form_invia_color: #ffffff;
$form_invia_width: auto;
$form_invia_height: auto;
$form_invia_border: 1px solid transparent;
$form_invia_fontsize: 1.25rem;

$form_error_color: red;


#nomesia_form {
	padding: 20px;
	margin: 10px 0;
	clear: both;
	background: $form_bg;
	fieldset {
		border: none;
		margin: 0;
		padding: 0;
	}
	input[type="hidden"] {
		display: none !important;
	}
	div {
		padding-bottom: 5px;
		margin: 5px 0px 5px 0;
		float: left;
		width: $form_div_width;
		&#submit_container{
			float: none;
			clear: both;
			margin: 0 auto;
		}
		select {
			width: $form_input_width;
			*width:130px;
		}
	}
	label {
		float: left;
		color: $form_label_color;
		font-size: $form_label_fontsize;
		font-weight: 600;
		display: block;
		width: $form_label_width;
		*width: 100px; /* IE7 vuole necessariamente un valore in pixel */
		&.error {
			display: block;
			width: 90%!important;
			font-size: 80% !important;
			padding: 2px 5px;
			color: $form_error_color;
			background: none;
			border: none;
		}
	}
	textarea, input, select {
		color: $form_input_color;
		background: $form_input_bg;
		border: $form_input_border;
		font-size: $form_input_fontsize;
		padding: $form_input_padding;
		width: $form_input_width;
		*width: 100px;
		float: left;
		display:inline-block;
		-webkit-transition: border linear .2s, box-shadow linear .2s;
		-moz-transition: border linear .2s, box-shadow linear .2s;
		-o-transition: border linear .2s, box-shadow linear .2s;
		transition: border linear .2s, box-shadow linear .2s;
		&:focus	{
			color: $form_input_color_focus;
			border: $form_input_border_focus;
			background: $form_input_bg_focus;
			outline: 0;
			-webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
			-moz-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
			box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
		}
	}
	textarea {
		height: 8rem;
	}
	#privacy_container, #marketing_container {
		border: none;
		float: left;
		padding: 0 0 5px;
		label{
			float: left;
			font-weight: normal;
			display: block;
			width: 92%;
			font-size: $form_privacy_fontsize;
			line-height: 1.2em;
			clear: none;
		}
		input {
			margin: 5px 20px 0 0;
			padding: 0;
			background: none;
			border: none;
			width: 15px;
			float: left;
		}
	}
	#more_info_container {
		label {
			width: 100%;
			font-size: 110%;
			font-weight: 400;
		}
	}
	.button_form {
		background: $form_invia_bg;
		color: $form_invia_color;
		font-weight: 600;
		border: $form_invia_border;
		width: $form_invia_width;
		display: block;
		margin: 15px auto;
		padding: .5rem 1rem;
		height: $form_invia_height;
		font-size: $form_invia_fontsize;
		float: none;
		cursor: pointer;
		transition: $transition-base;
		&:hover, &:focus {
			background: $form_invia_bg_hover;
			color: $form_invia_color;
		}
	}
	.checked {
		display: none!important;
	}
	.field_status{
		margin: 0;
		padding: 0;
		&.error{
			display: block;
		}
	}
	span.ast {
		color: red;
	}
}

@media \0screen {
  #nomesia_form textarea:focus, #nomesia_form input:focus, #nomesia_form select:focus{outline: thin dotted;}
}

@include media-breakpoint-down(xs) {

	#nomesia_form label {width: 100% !important;}
	#nomesia_form input {width: 100% !important;}
	#nomesia_form select {width: 100% !important;}
	#nomesia_form textarea {width: 100% !important;}
	#nomesia_form #privacy_container input, #nomesia_form #marketing_container input {width: 5% !important; margin: 0 4% 0 0;}
	#nomesia_form #privacy_container label, #nomesia_form #marketing_container label{width: 91% !important; padding: 0;}
	#nomesia_form .button_form {white-space: normal;}
}


/* ======= BACK TO TOP ======= */

#top {
	position: fixed;
	width: 40px;
	height: 40px;
	background: #4671b5;
	bottom: 20px;
	right: 20px;
	cursor: pointer;
	border: 1px solid #eee;
	transition: $transition-base;
	&:hover {
		background: lighten(#000, 15%);
	}
	#top-arrow {
		-o-transform: rotate(45deg);
		-moz-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		border-top: 5px solid $primary;
		border-left: 5px solid $primary;
		border-bottom: none;
		border-right: none;
		width: 10px;
		height: 10px;
		margin: 15px auto;
	}
}